import axios from 'axios';
import bancos from '../JS/bancos.json';
import alterarFase from './alterarFase';


const incluirAgille = async (novoResultado, responseQuali) => {
  try {
    // Certifique-se de que o token está correto e válido
    const token = 'A6pWwCwciYOXbR7Se8DtIn3ezAv_Mzh68EtPU3Ib5tnP6UuOt6ZXK2Vf-V-gHsRKgvzR-yGXQsWuAs4UUnzOnruDuLhntqWfHAUXyA6r11j9ztgiYN3l4O9j3IDnH6ByMGbqOftSPzYGutF8ggAYkORDhrd8RmRDwnadqoG7gcpe1qz5Zhva_K-jzXKcIygkxwLC5aH3isA8Xtl6o_70EHSK5KPN6dUgoUrNf8Vt-AuRiuIv4lYjfBVe_wOwvxnoMbGNrp7vOARFCL2ANYeaSikFc2bTMmSSreDiYvvxiK7NObzYN5TcnwW-rcUe2GOarYztTkzaACHkUs_ssfPACeYjnI2UR1iF8XoofNi5b_1_Pukzx3D6pqROBSsxGWVg5RWoe-c7LZS4NVj946Huk-hUPoxdA56R7jJXRH_ia2lGTLGzu63TcsWY-qxs6syX'; // Insira o token correto aqui
    if (!token) {
      throw new Error('Token inválido ou ausente.');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Adiciona o prefixo Bearer
        'Content-Type': 'application/json',
      },
    };

    const infoObrigatorias = novoResultado?.informacoesobrigatorias || {};
    const contratosSelecionados = novoResultado?.contratosSelecionados || [];
    const camposFixos = novoResultado?.camposFixos || {};
    const qualiResponse = responseQuali || {};

    // Usando os dados do response.data da função incluirQuali
    const signatureUrl = qualiResponse?.signatureLink || "";  // Aqui está o campo signature_url retornado pelo incluirQuali

     // Função para encontrar o banco correspondente
     const encontrarBancoId = (bankCode) => {
      const banco = bancos.find(b => b.CodigoBanco === bankCode);
      return banco ? banco.Id : bankCode; // Se não encontrar, mantém o bankCode original
    };

    // Obtendo o BancoId correto a partir do Código do Banco no qualiResponse
    const bancoIdDadosBancarios = encontrarBancoId(qualiResponse.bankAccount?.bankCode);

    // Obtendo o BancoId correto a partir do Código do Banco em contratosSelecionados (para portabilidade)
    const bancoIdPortabilidade = encontrarBancoId(qualiResponse.originalContract?.lenderCode);

    const sexoMap = {
      'Masculino': 1,
      'Feminino': 2,
    };

    const sexoConvertido = sexoMap[infoObrigatorias.sexo] || 0; // Valor padrão caso não seja encontrado

    const dadosParaEnvio = {
      Nome: qualiResponse.name,
      Cpf: qualiResponse.documentNumber,
      Matricula: infoObrigatorias.beneficio,
      TipoContrato: camposFixos.TipoContrato || 3,
      TipoOperacao: camposFixos.TipoOperacao || 2,
      DataContrato: '2023-11-09',
      ConvenioId: camposFixos.ConvenioId,
      BancoId: camposFixos.BancoIdQuali,
      TabelaId: qualiResponse.document?.extraFields?.tabelaAgilus || '',
      PromotoraId: camposFixos.PromotoraQuali,
      ValorBruto: qualiResponse.baseValue,
      NumeroContrato: qualiResponse.originalContract.number,
      NumeroProposta: qualiResponse.contractNumber,
      Prazo: qualiResponse.installments,
      ValorParcela: qualiResponse.installmentValue,
      VendedorId: infoObrigatorias.vendedorId,
      Link: qualiResponse.signatureLink,  // Aqui você está passando o valor de signature_url
      DadosPortabilidade: {
        BancoId: bancoIdPortabilidade,
        Saldo: qualiResponse.baseValue,
        PrazoRestante: qualiResponse.installments,
        NumeroContrato: qualiResponse.originalContract?.number || '',
        ValorParcela: contratosSelecionados.valorParcela
      },
      DataNascimento: qualiResponse.birthDate,
      Especie: qualiResponse.benefitTypeCode,
      Salario: qualiResponse.netValue,
      NomeMae: qualiResponse.motherName,
      TipoDocumento: 0,
      NumeroDocumento: qualiResponse.document?.number || '',
      OrgaoExpedidor: qualiResponse.document?.issuingEntity || 'SSP/SP',
      DataExpedicao: qualiResponse.document?.issuingDate || '',
      Naturalidade: infoObrigatorias.naturalidade || 'Brasileira',
      EstadoCivil: infoObrigatorias.estadoCivil || 0,
      Sexo: sexoConvertido,
      UfSolicitacaoBeneficio: infoObrigatorias.estado,
      DadosBancarios: {
        TipoConta: infoObrigatorias.tipoConta || 0,
        BancoId: bancoIdDadosBancarios,
        Agencia: qualiResponse.bankAccount?.agency || '',
        NumeroConta: qualiResponse.bankAccount?.accountNumber || '',
      },
      Endereco: {
        Rua: infoObrigatorias.endereco,
        Numero: infoObrigatorias.numero,
        Complemento: infoObrigatorias.complemento || 'Casa',
        Bairro: infoObrigatorias.bairro,
        Cidade: infoObrigatorias.cidade,
        Uf: infoObrigatorias.estado,
        Cep: infoObrigatorias.cep,
      },
      Telefone: {
        Ddd: infoObrigatorias.ddd,
        Numero: infoObrigatorias.telefone,
      },
    };

    console.log('Dados para envio (Agille):', dadosParaEnvio);

    const response = await axios.post(
      'https://agiluscrm.com.br:40000/api/customapi/executar?customApiId=34',
      dadosParaEnvio,
      config
    );

    const vendaId = response.data.Id;  // Pegando o ID da venda retornado pela API

    await alterarFase(vendaId, 15103, '', {}, 'Incluido via portal')
    if (signatureUrl === null || '') {
      await alterarFase(vendaId, 14025, '', {}, 'Verique com o Backoffice')
    } else {
      await alterarFase(vendaId, 12291, '', {}, '')
    }


    return vendaId;  // Retorna o ID da venda
  } catch (error) {
    console.error('Erro ao incluir no Agille:', error.response?.data || error.message || error.detail);
    throw error;
  }
};

export default incluirAgille;
